<template>
  <v-container fluid>
    <new-edit-sheet />
    <delete-dialog />
    <v-row align="center" justify="space-between" no-gutters>
      <v-col cols="8">
        <settings-breadcrumbs v-model="project" />
      </v-col>
      <v-col class="text-right">
        <v-btn color="info" class="mr-2" @click="createEditShow()">
          <v-icon class="mr-1">mdi-plus</v-icon>
          New Workflow
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-alert outlined dismissible icon="mdi-play-network-outline" prominent type="info">
          <div>
            <h3>Seamless Integration with Workflows</h3>
            <p>UnStruct's workflows feature enables a seamless extension into existing workflow orchestration systems, offering unparalleled flexibility and efficiency in IAO management and operational tasks.</p>
            <ul>
              <li>Integration with a broad range of workflow orchestration systems using custom webhooks.</li>
              <li>The ability to automate a wide array of actions, from IAO procedures to routine operational tasks.</li>
              <li>Real-time status reporting back to UnStruct, ensuring visibility and traceability of each workflow's execution.</li>
            </ul>
          </div>
        </v-alert>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-card elevation="0">
          <v-card-title>
            <v-text-field v-model="q" append-icon="search" label="Search" single-line hide-details clearable />
          </v-card-title>
          <v-row>
          <v-col cols="12" md="6" lg="4" v-for="item in items" :key="item.id">
            <v-card outlined class="pa-3 mb-3">
              <v-card-title>
                <span>
                  {{ item.name }}
                </span>
                <v-spacer></v-spacer>
                <v-chip :color="item.enabled ? 'success' : 'grey'" dark>
                  <v-icon left>{{ item.enabled ? 'mdi-lightbulb-on-outline' : 'mdi-lightbulb-off-outline' }}</v-icon>
                  {{ item.enabled ? 'Enabled' : 'Disabled' }}
                </v-chip>
              </v-card-title>
              <v-card-subtitle class="mt-4">
                <div class="mb-2">
                  <v-icon left>mdi-vector-intersection</v-icon>
                  {{ item.plugin_instance.plugin.title }}
                </div>
                <v-icon small>mdi-projector-screen-outline</v-icon>
                Project:
                <project-card :project="item.plugin_instance.project" />
                <div class="mt-3 mb-2">
                  <strong>
                    <v-icon small class="mr-1">mdi-axis-arrow-info</v-icon>
                    Description:
                  </strong>
                  {{ item.description }}
                </div>
                <div class="mb-2">
                  <strong>
                    <v-icon small>mdi-format-list-bulleted</v-icon>
                    Plugin Type:
                  </strong>
                    <v-icon>mdi-format-superscript-outline</v-icon>
                    {{ item.plugin_instance.plugin.type }}
                </div>
                <div class="mb-2">
                  <strong>
                    <v-icon small>mdi-cog-outline</v-icon>
                    Plugin Workflow Params:
                  </strong>
                  <v-chip>
                    <v-icon left>mdi-cog-outline</v-icon>
                    {{ item.parameters.length }} Parameters
                  </v-chip>
                </div>
              </v-card-subtitle>
              <v-card-text>
                <div class="mb-2">
                  <strong>
                    <v-icon small>mdi-axis-arrow-info</v-icon>
                    Plugin Description:
                  </strong>
                  <v-icon >mdi-axis-arrow-info</v-icon>
                  {{ item.plugin_instance.plugin.description }}
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn color="primary" text @click="createEditShow(item)">View / Edit</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"

import SettingsBreadcrumbs from "@/components/SettingsBreadcrumbs.vue"
import DeleteDialog from "@/workflow/DeleteDialog.vue"
import NewEditSheet from "@/workflow/NewEditSheet.vue"
import ProjectCard from '@/project/ProjectCard.vue'

export default {
  name: "WorkflowTable",

  components: {
    DeleteDialog,
    NewEditSheet,
    SettingsBreadcrumbs,
    ProjectCard,
  },
  data() {
    return {
      headers: [
        { text: "Name", value: "name", sortable: true },
        { text: "Description", value: "description", sortable: false },
        { text: "Enabled", value: "enabled", sortable: true },
        { text: "ResourceId", value: "resource_id", sortable: true },
        { text: "Plugin", value: "plugin_instance", sortable: true },
        { text: "", value: "data-table-actions", sortable: false, align: "end" },
      ],
    }
  },

  computed: {
    ...mapFields("workflow", [
      "table.options.q",
      "table.options.page",
      "table.options.itemsPerPage",
      "table.options.sortBy",
      "table.options.descending",
      "table.options.filters.project",
      "table.loading",
      "table.rows.items",
      "table.rows.total",
    ]),
    ...mapFields("route", ["query"]),
  },

  created() {
    this.project = [{ name: this.query.project }]

    this.getAll()

    this.$watch(
      (vm) => [vm.page],
      () => {
        this.getAll()
      }
    )

    this.$watch(
      (vm) => [vm.q, vm.itemsPerPage, vm.sortBy, vm.descending, vm.project],
      () => {
        this.page = 1
        this.$router.push({ query: { project: this.project[0].name } })
        this.getAll()
      }
    )
  },

  methods: {
    ...mapActions("workflow", ["getAll", "createEditShow", "removeShow"]),
  },
}
</script>
